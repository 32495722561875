import React, { useEffect, useState } from 'react';
import { Link, type To } from 'react-router-dom';

interface SeoLinkProps {
  seoTo: To;
  uiTo: To | undefined;
  label: string;
  title: string | undefined;
  className?: string | undefined;
}

const SeoLink: React.FC<SeoLinkProps> = ({ seoTo, uiTo, label, title, className }: SeoLinkProps) => {

  const [to, setTo] = useState<To>(seoTo);

  useEffect(() => {
    if (uiTo) {
      setTo(uiTo);
    }
  }, [uiTo]);

  return (
    <Link
      to={to}
      title={title}
      className={className}
    >
      {label}
    </Link>
  );
};

export default SeoLink;
