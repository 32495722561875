import loadable from '@loadable/component';
import React, { useCallback, useMemo } from 'react';
import type { Path } from 'react-router-dom';
import SeoLink from '../../../../framework/base/common/components/shared/elements/SeoLink';
import type { DeepReadonly } from '../../../../framework/base/common/constants/types/UtilityTypes';
import type BaseGisEntityTypeEnum from '../../../../framework/gis/common/constants/enums/BaseGisEntityTypeEnum';
import type { IHomePageUrlGenerator } from '../../../../framework/gis/common/hooks/IHomePageUrlGenerator';
import EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import type { HomePageQuery } from '../../../models/system/home/queries/HomePageQuery';
import styles from './HomePageContentNavigationTabs.module.scss';

const HomePageContentNavigationTabsLoading = loadable(() => import(/* webpackChunkName: "page-home-page-menu-tabs-loading" */'./HomePageContentNavigationTabsLoading'));

interface HomePageContentNavigationLinkDefinition {
  entityType: EntityTypeEnum;
  label: string;
  recipeUi: ((draft: DeepReadonly<HomePageQuery>, tab: EntityTypeEnum) => DeepReadonly<HomePageQuery>);
  recipeSeo: ((draft: DeepReadonly<HomePageQuery>, tab: EntityTypeEnum) => DeepReadonly<HomePageQuery>);
}

interface HomePageContentNavigationLink {
  entityType: EntityTypeEnum;
  label: string;
  seoTo: Partial<Path>,
  uiTo: Path,
}

const recipeSeo = (draft: DeepReadonly<HomePageQuery>, tab: EntityTypeEnum): DeepReadonly<HomePageQuery> => ({
  tab,
  location: draft.location,
  expand: undefined,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const recipeUi = (draft: DeepReadonly<HomePageQuery>, tab: EntityTypeEnum): DeepReadonly<HomePageQuery> => ({
  tab,
  map: draft.map,
  location: draft.location,
  expand: undefined,
});

const linksDefinitions: HomePageContentNavigationLinkDefinition[] = [
  {
    entityType: EntityTypeEnum.Advert,
    label: 'Ogłoszenia',
    recipeSeo: recipeSeo,
    recipeUi: recipeUi,
  },
  {
    entityType: EntityTypeEnum.Shelter,
    label: 'Schroniska',
    recipeSeo: recipeSeo,
    recipeUi: recipeUi,
  },
  // {
  //   entityType: EntityTypeEnum.Petsitter,
  //   label: 'Opieka nad zwierzątami',
  //   recipeSeo: recipeSeo,
  //   recipeUi: recipeUi,
  // },
];

const createLinks = (
  activeTab: EntityTypeEnum | BaseGisEntityTypeEnum | undefined,
  generateUrl: (recipe: (draft: DeepReadonly<HomePageQuery>) => DeepReadonly<HomePageQuery>) => Path,
): HomePageContentNavigationLink[] => {
  const items: HomePageContentNavigationLink[] = linksDefinitions.map((definition) => {
    const seoPath = generateUrl((draft: DeepReadonly<HomePageQuery>): DeepReadonly<HomePageQuery> => ({
      ...definition.recipeSeo(draft, definition.entityType),
    }));

    const uiPath = generateUrl(((draft: DeepReadonly<HomePageQuery>): DeepReadonly<HomePageQuery> => ({
      ...definition.recipeUi(draft, definition.entityType),
    })));

    const link: HomePageContentNavigationLink = {
      ...definition,
      seoTo: seoPath,
      uiTo: uiPath,
    };

    return link;
  });

  return orderLinks(activeTab, items);
};

const orderLinks = (activeTab: EntityTypeEnum | BaseGisEntityTypeEnum | undefined, items: HomePageContentNavigationLink[]): HomePageContentNavigationLink[] => {
  const index = items.findIndex(i => i.entityType === activeTab);
  const active = items.splice(index, 1)[0];

  const result = active
    ? [
      active,
      ...items,
    ]
    : [
      ...items,
    ];

  return result;
};

function getOrder(tab: EntityTypeEnum | BaseGisEntityTypeEnum | undefined): number {
  switch (tab) {
    case EntityTypeEnum.Advert:
      return 0;
    case EntityTypeEnum.Shelter:
      return 1;
    case EntityTypeEnum.Vet:
      return 2;
    case EntityTypeEnum.Petsitter:
      return 3;
    default:
      return 4;
  }
}

interface HomePageContentNavigationTabsProps {
  urlGenerator: IHomePageUrlGenerator;
  activeTab: EntityTypeEnum | BaseGisEntityTypeEnum | undefined;
  isFetching: boolean | undefined;
}

const HomePageContentNavigationTabs: React.FC<HomePageContentNavigationTabsProps> = ({ urlGenerator, activeTab, isFetching }: HomePageContentNavigationTabsProps) => {

  const links = useMemo<HomePageContentNavigationLink[]>(() => {
    return createLinks(activeTab, urlGenerator.generateHomePageUrlNoImmer);
  }, [activeTab, urlGenerator.generateHomePageUrlNoImmer]);

  const handleOnClickToggleFilters = useCallback((event: React.MouseEvent<HTMLButtonElement>): void => {
    event.currentTarget.blur();

    urlGenerator.redirectHomePageNoImmer((draft: DeepReadonly<HomePageQuery>): DeepReadonly<HomePageQuery> => {
      const result: DeepReadonly<HomePageQuery> = {
        ...draft,
        filters: draft.expand
          ? {
            animal: draft.filters?.animal,
            type: draft.filters?.type,
            advertPublicCreatedAtPeriod: draft.filters?.advertPublicCreatedAtPeriod,
          }
          : draft.filters,
        expand: !draft.expand ? true : undefined,
      };

      return result;
    });
  }, [urlGenerator]);

  return (
    <ul id={styles['app-ul']}>
      {links?.map((link, index) => (
        <li
          key={`${index}-${link.entityType}`}
          className={styles['app-li']}
          data-order={getOrder(link.entityType)}
        >
          <h3 className={styles['app-header']}>
            <SeoLink
              seoTo={link.seoTo}
              uiTo={link.uiTo}
              title={link.label}
              label={link.label}
              className={`${styles['app-link']}${(activeTab ?? EntityTypeEnum.Advert) === link.entityType ? ` ${styles['active']}` : ''}`}
            />
          </h3>
        </li>
      ))}
      {(isFetching ?? false) && (
        <HomePageContentNavigationTabsLoading />
      )}
      <li id={styles['app-item-filters']}>
        {(urlGenerator.query.tab === undefined || urlGenerator.query.tab === EntityTypeEnum.Advert) && (
          <button
            type='button'
            id={styles['app-item-filters-button']}
            onClick={handleOnClickToggleFilters}
          >
            <span id={styles['app-item-filters-button-span']}>
              {urlGenerator.query.expand ? 'Wyłącz filtry' : 'Pokaż filtry'}
            </span>
          </button>
        )}
      </li>
    </ul>
  );
};

export default HomePageContentNavigationTabs; 
